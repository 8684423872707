import React, { useEffect, useState } from 'react';
import { Container,  Box, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';  
import CourseForm from './courseForm';
import NepalCourseTable from '../../../RecordTable/StickyHeaderTable';
import ApiClient from '../../../../config/configAxios';
import { toast } from 'react-toastify';

const columns =["S.N","Course Title","Overview","Eligibility","Preparation Tips","FAQ","Exam Pattern","Syllabus","Cover Image","Image Format"];
const NepalCourseForm = () => {
  const [courseData, setCourseData] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false); 
  const [currentCourse, setCurrentCourse] = useState(null); 

  useEffect(()=>{
    const fetchNepaliCourses = ()=>{
      ApiClient.get('/studynepalicourses').then((response)=>{
        setCourseData(response.data.studyNepalCourses);
        console.table(response.data.studyNepalCourses.eligibility);
      }).catch((error)=>{
        console.log(error);
      });
    }
    fetchNepaliCourses();
  },[]);
  // Handle form submission to add or update the course
  const handleFormSubmit = (data) => {
    // Log the data object containing form field values
    // console.log("Form Submitted with the following data:", data);
    const submitNepaliCourse = new FormData();
    submitNepaliCourse.append("courseTitle",data.courseTitle);
    submitNepaliCourse.append("overview",data.overview);
    submitNepaliCourse.append("eligibility",JSON.stringify(data.eligibility));
    submitNepaliCourse.append("preparationTips",JSON.stringify(data.preparationTips));
    submitNepaliCourse.append("faq",JSON.stringify(data.faq));
    submitNepaliCourse.append("examPattern",JSON.stringify(data.examPattern));
    submitNepaliCourse.append("syllabus",JSON.stringify(data.syllabus));
    submitNepaliCourse.append("courseImage",data.courseImage);

    
    if (isEditing) {
      // console.log("Editing mode",currentCourse);
      // setCourseData(courseData.map(course => course.courseTitle === currentCourse.courseTitle ? data : course)); // Update existing course
      // console.log("on editing",data);
      ApiClient.patch(`/studynepalicourses/${currentCourse.id}`,submitNepaliCourse,{
        headers:{
          "Content-Type":'multipart/form-data'
        }
      }).then((response)=>{
        // console.log(response);
        if(response.data.success){
          toast.success("Nepali Course Updated.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }else{
          toast.error("Sorry, Something wrong.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }).catch((error)=>{
        console.log(error)
      });
    } else{
        ApiClient.post(`/studynepalicourses`,submitNepaliCourse,{
          headers:{
            "Content-Type":'multipart/form-data'
          }
        }).then((response)=>{
          // console.log(response);
          if(response.data.success){
            toast.success("Nepali Course added.",{
              position: "top-right",
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }else{
            toast.error("Sorry, Something wrong.",{
              position: "top-right",
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }).catch((error)=>{
          console.log(error);
        })
    }
    setIsFormOpen(false); // Close the form after submission
    setIsEditing(false);
    setCurrentCourse(null); // Reset current course after submission
  };

  // Handle editing a course
  const handleEdit = (course) => {
    console.log("List edit course.");
    console.log(course.eligibility);
    setIsFormOpen(true);
    setIsEditing(true);
    setCurrentCourse(course);
  };

  // Handle adding a new course
  const handleAddNew = () => {
    setIsFormOpen(true);
    setIsEditing(false);
    setCurrentCourse(null); // Clear current course when adding new course
  };

  // Handle canceling the form
  const handleCancelForm = () => {
    setIsFormOpen(false);
    setIsEditing(false);
    setCurrentCourse(null); // Reset course data on cancel
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        // backgroundColor:"green",
        height: "100%",
        paddingBottom:"20px"
      }}
    >
      {/* <Typography variant="h6" gutterBottom>
        Nepali Course
      </Typography> */}

      <Grid container spacing={2} direction="column" alignItems="center">
        {/* Add New Course button only visible when form is closed */}
        {!isFormOpen && (
          <Grid item xs={12} sm={6} md={4}>
            <Button 
              variant="contained" 
              onClick={handleAddNew} 
              sx={{ marginBottom: 2 }}
              fullWidth
            >
              Add New Course
            </Button>
          </Grid>
        )}

        {/* Show the form when isFormOpen is true */}
        {isFormOpen && (
          <Grid item xs={12} sm={8} md={6}>
            <Box sx={{ marginBottom: 3 }}>
              <CourseForm
                onSubmit={handleFormSubmit}
                initialData={currentCourse}
                onCancel={handleCancelForm} // Cancel form action
              />
            </Box>
          </Grid>
        )}
      </Grid>

      {/* Show the course table regardless of the form state */}
      <Box
        sx={{
          mt:3,
          width:{xs:'100%',sm:'94%',md:"96%",lg:"100%"}
        }}
      >
          <NepalCourseTable 
            rows={courseData} 
            column={columns}
            url={'/studynepalicourses'}
            onEdit={handleEdit} 
            />
        </Box>
    </Container>
  );
};

export default NepalCourseForm;