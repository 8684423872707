import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  IconButton,
  Chip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ApiClient from '../../../../config/configAxios';

import { toast } from 'react-toastify';

const UniversityProgramsForm = ({ open, onClose, editData }) => {
  const [univName, setUnivName] = useState('');
  const [programs, setPrograms] = useState([]);
  const [currentProgram, setCurrentProgram] = useState('');

  // Effect to load data into the form when editing
  useEffect(() => {
    if (editData) {
      setUnivName(editData.universityName);
      setPrograms(editData.programs); // Set programs as an array
    } else {
      setUnivName('');
      setPrograms([]);
    }
  }, [editData]);

  const handleAddProgram = () => {
    if (currentProgram.trim() && !programs.includes(currentProgram.trim())) {
      setPrograms((prev) => [...prev, currentProgram.trim()]);
      setCurrentProgram('');
    }
  };

  const handleDeleteProgram = (programToDelete) => {
    setPrograms((prev) => prev.filter((program) => program !== programToDelete));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      universityName: univName,
      programs, 
    };
    // console.log('Form Data:', formData);
    if(editData){
      // console.log("start editing...");
      // console.log(editData);

      ApiClient.patch(`/universityprograms/${editData.id}`,formData).then((response)=>{
        // console.log(response);
        if(response.data.success){
          toast.success("University Updated.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }else{
          toast.error("Sorry, Something wrong.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }).catch((error)=>{
        console.log(error);
      })
    }else{
      console.log("add new record");
      ApiClient.post(`/universityprograms`,formData).then((response)=>{
        // console.log(response);
        if(response.data.success){
            toast.success("University Added.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }else{
            toast.error("Sorry, Something wrong.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }).catch((error)=>{
        console.log(error);
      });
    }
    // onSubmit(formData); // Pass form data to parent component
    onClose(); // Close the dialog after submission
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{editData ? 'Edit University Programs' : 'Add University Programs'}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="University Name"
            variant="outlined"
            value={univName}
            onChange={(e) => setUnivName(e.target.value)}
            required
            fullWidth
          />
          <TextField
            label="Add Program"
            variant="outlined"
            value={currentProgram}
            onChange={(e) => setCurrentProgram(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleAddProgram} color="primary">
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {programs.map((program, index) => (
              <Chip
                key={index}
                label={program}
                onDelete={() => handleDeleteProgram(program)}
                color="primary"
                variant="outlined"
              />
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UniversityProgramsForm;