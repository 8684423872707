import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  IconButton,
  CircularProgress,
  InputAdornment,
  Container,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import PreparationTipsField from './CourseContent/preparation';
import AbroadCourseImageUploader from './CourseContent/CourseImageUploader';

const CourseForm = ({ initialData, onCancel, isAbroadCourse, onSubmit }) => {
  const [formData, setFormData] = useState({
    courseTitle: '',
    eligibility: [""],
    overview: '',
    preparationTips: [''],
    syllabus: [{ topic: '', subtopics: [''] }],
    testFormat: [{ topic: '', description: '' }],
    faq: [{ question: '', answer: '' }],
    courseImage: null,
    examPattern: [{ topic: '', description: '' }],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (initialData) {
      setFormData({
        courseTitle: initialData.courseTitle || '',
        eligibility: initialData.eligibility || [''],
        overview: initialData.overview || '',
        preparationTips: initialData.preparationTips || [''],
        syllabus: initialData.syllabus || [{ topic: '', subtopics: [''] }],
        testFormat: initialData.testFormat || [{ topic: '', description: '' }],
        faq: initialData.faq || [{ question: '', answer: '' }],
        courseImage: initialData.courseImage || null,
        examPattern: initialData.examPattern || [{ topic: '', description: '' }],
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleArrayChange = (field, index, value) => {
    const updatedArray = [...formData[field]];
    updatedArray[index] = value;
    setFormData({ ...formData, [field]: updatedArray });
  };

  const handleObjectArrayChange = (field, index, key, value) => {
    const updatedArray = [...formData[field]];
    updatedArray[index][key] = value;
    setFormData({ ...formData, [field]: updatedArray });
  };

  const addField = (field, defaultValue) => {
    setFormData({ ...formData, [field]: [...formData[field], defaultValue] });
  };

  const removeField = (field, index) => {
    if (formData[field].length > 1) {
      const updatedArray = formData[field].filter((_, i) => i !== index);
      setFormData({ ...formData, [field]: updatedArray });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setTimeout(() => {
      onSubmit(formData);
      setIsSubmitting(false);
    }, 2000);
  };
  // Define the addTopicWithSubtopic function
  const addTopicWithSubtopic = () => {
    setFormData({
      ...formData,
      syllabus: [...formData.syllabus, { topic: '', subtopics: [''] }],
    });
  };

  // Define the addSubtopic function
  const addSubtopic = (index) => {
    const updatedSyllabus = [...formData.syllabus];
    updatedSyllabus[index].subtopics.push('');
    setFormData({ ...formData, syllabus: updatedSyllabus });
  };

  return (
    <Container maxWidth="md" sx={{ margin: 'auto', padding: 2 }}>
      <form onSubmit={handleSubmit}>
        {/* Course Title */}
        <TextField
          label="Course Title"
          name="courseTitle"
          value={formData.courseTitle}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />

        {/* Course Overview */}
        <TextField
          label="Course Overview"
          name="overview"
          value={formData.overview}
          onChange={handleChange}
          fullWidth
          multiline
          rows={4}
          margin="normal"
          required
        />

        {/* Eligibility */}
        {!isAbroadCourse && (
          <fieldset style={{ border: '1px solid #ccc', padding: 10, marginBottom: 12 }}>
            <legend>Eligibility</legend>
            {formData.eligibility.map((item, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label={`Eligibility ${index + 1}`}
                  value={item}
                  onChange={(e) => handleArrayChange('eligibility', index, e.target.value)}
                  fullWidth
                  margin="normal"
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {index === formData.eligibility.length - 1 && (
                          <IconButton onClick={() => addField('eligibility', '')}>
                            <AddIcon />
                          </IconButton>
                        )}
                        {formData.eligibility.length > 1 && (
                          <IconButton onClick={() => removeField('eligibility', index)}>
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            ))}
          </fieldset>
        )}

        {/* Preparation Tips */}
        <fieldset style={{ border: '1px solid #ccc', padding: 10, marginBottom: 12 }}>
          <legend>Preparation Tips</legend>
          <PreparationTipsField
            preparationTips={formData.preparationTips}
            setPreparationTips={(newTips) => setFormData({ ...formData, preparationTips: newTips })}
          />
        </fieldset>

        {/* FAQ */}
        <fieldset style={{ border: '1px solid #ccc', padding: 10, marginBottom: 12 }}>
          <legend>FAQ</legend>
          {formData.faq.map((faqItem, index) => (
            <Box key={index}>
              <TextField
                label={`Question ${index + 1}`}
                value={faqItem.question}
                onChange={(e) => handleObjectArrayChange('faq', index, 'question', e.target.value)}
                fullWidth
                margin="normal"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {index === formData.faq.length - 1 && (
                        <IconButton onClick={() => addField('faq', { question: '', answer: '' })}>
                          <AddIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label={`Answer ${index + 1}`}
                value={faqItem.answer}
                onChange={(e) => handleObjectArrayChange('faq', index, 'answer', e.target.value)}
                fullWidth
                margin="normal"
                required
              />
              {formData.faq.length > 1 && (
                <IconButton onClick={() => removeField('faq', index)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          ))}
        </fieldset>

        
        {/* Exam Pattern */}
{!isAbroadCourse && (
  <fieldset style={{ border: '1px solid #ccc', padding: 10, marginBottom: 12 }}>
    <legend>Exam Pattern</legend>
    {formData.examPattern.map((examPatternItem, index) => (
      <Box key={index}>
        <TextField
          label={`Topic ${index + 1}`}
          value={examPatternItem.topic}
          onChange={(e) => handleObjectArrayChange('examPattern', index, 'topic', e.target.value)}
          fullWidth
          margin="normal"
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {index === formData.examPattern.length - 1 && (
                  <IconButton onClick={() => addField('examPattern', { topic: '', description: '' })}>
                    <AddIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label={`Description ${index + 1}`}
          value={examPatternItem.description}
          onChange={(e) => handleObjectArrayChange('examPattern', index, 'description', e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        {formData.examPattern.length > 1 && (
          <IconButton onClick={() => removeField('examPattern', index)}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    ))}
  </fieldset>
)}


        {/* Syllabus or Test Format (Conditional Rendering) */}
{isAbroadCourse ? (
  <fieldset sx={{ border: '1px solid #ccc', padding: 10, marginBottom: 12 }}>
    <legend>Test Format</legend>
    {formData.testFormat.map((testFormatItem, index) => (
      <Box key={index}>
        <TextField
          label={`Test Topic ${index + 1}`}
          value={testFormatItem.topic}
          onChange={(e) => handleObjectArrayChange('testFormat', index, 'topic', e.target.value)}
          fullWidth
          margin="normal"
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {index === formData.testFormat.length - 1 && (
                  <IconButton
                    onClick={() => addField('testFormat', { topic: '', description: '' })}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label={`Description ${index + 1}`}
          value={testFormatItem.description}
          onChange={(e) =>
            handleObjectArrayChange('testFormat', index, 'description', e.target.value)
          }
          fullWidth
          margin="normal"
          required
        />
        {formData.testFormat.length > 1 && (
          <IconButton onClick={() => removeField('testFormat', index)}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    ))}
  </fieldset>
) : (
  <fieldset style={{ border: '1px solid #ccc', padding: 10, marginBottom: 12 }}>
    <legend>Syllabus</legend>
    {formData.syllabus.map((syllabusItem, index) => (
      <Box key={index} sx={{ marginBottom: 2 }}>
        {/* Topic TextField */}
        <TextField
          label={`Topic ${index + 1}`}
          value={syllabusItem.topic}
          onChange={(e) => handleObjectArrayChange('syllabus', index, 'topic', e.target.value)}
          fullWidth
          margin="normal"
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {index === formData.syllabus.length - 1 && (
                  <IconButton onClick={addTopicWithSubtopic}>
                    <AddIcon />
                  </IconButton>
                )}
                {/* Allow deleting only if it's not the first topic */}
                {formData.syllabus.length > 1 && index > 0 && (
                  <IconButton
                    onClick={() => {
                      // Remove the selected syllabus item (topic and subtopics)
                      setFormData({
                        ...formData,
                        syllabus: formData.syllabus.filter((_, i) => i !== index),
                      });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />

        {/* Subtopics for each topic */}
        {syllabusItem.subtopics.map((subtopic, subIndex) => (
          <Box key={subIndex} sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              label={`Subtopic ${subIndex + 1}`}
              value={subtopic}
              onChange={(e) =>
                handleObjectArrayChange('syllabus', index, 'subtopics', [
                  ...syllabusItem.subtopics.slice(0, subIndex),
                  e.target.value,
                  ...syllabusItem.subtopics.slice(subIndex + 1),
                ])
              }
              fullWidth
              margin="normal"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {subIndex === syllabusItem.subtopics.length - 1 && (
                      <IconButton onClick={() => addSubtopic(index)}>
                        <AddIcon />
                      </IconButton>
                    )}
                    {syllabusItem.subtopics.length > 1 && (
                      <IconButton
                        onClick={() =>
                          setFormData({
                            ...formData,
                            syllabus: formData.syllabus.map((item, idx) =>
                              idx === index
                                ? {
                                    ...item,
                                    subtopics: item.subtopics.filter((_, i) => i !== subIndex),
                                  }
                                : item
                            ),
                          })
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        ))}
      </Box>
    ))}
  </fieldset>
)}

        {/* Course Image */}
        <AbroadCourseImageUploader
          courseImage={formData.courseImage}
          setCourseImage={(image) => setFormData({ ...formData, courseImage: image })}
        />

        {/* Submit and Cancel Buttons */}
        <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
            {isSubmitting ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default CourseForm;