//main component
import React, { useEffect, useState } from 'react';
import StudentTable from './studentTable';
import StudentRegistration from './studentRegistration';
import { Box, Button } from '@mui/material';
import apiClient from '../../../config/configAxios';

const StudentManagement = () => {
  const [students, setStudents] = useState([]);

  const columns = ["S.N","Student Name",
    "Email","Contact","Address","Graduated College","Admission On","Qualification",
    "Destination","Preparation Test","Admitted College","Program","Feedback","Alumini","Photo"
  ]
  const [isRegistering, setIsRegistering] = useState(false);


  const registerStudent = (newStudent) => {
    // console.log("I don't know when , will execute.");
    setStudents((prevStudents) => [...prevStudents, { ...newStudent, sid: prevStudents.length + 1 }]);
    setIsRegistering(false);  // Hide registration form after successful registration
  };

  const toggleRegistrationForm = () => {
    setIsRegistering(!isRegistering);  // Toggle the state to show/hide registration form
  };
  useEffect(()=>{
    const fetchStudentDetail = ()=>{
      apiClient.get(`/register`).then((response)=>{
        // console.log(response);
        setStudents(response.data.StudentDetail);
      }).catch((error)=>{
        console.log(error);
      });
    }
    fetchStudentDetail();
  },[]);
  return (
    <Box
      sx={{
        // backgroundColor:"red",
        width: "100%"
      }}
    >
      <Box
        sx={{
          // backgroundColor:"green",
          width: "100%",
          // border:"1px solid blue"
        }}
      >
            {/* Show Register Button */}
            <Box>
              <Button onClick={toggleRegistrationForm} variant='contained' sx={{
                mb:2
              }}>
                {isRegistering ? 'Cancel Registration' : 'Register New Student'}
              </Button>
            </Box>
            
            {/* Student Registration Form */}
            {isRegistering && <StudentRegistration onRegister={registerStudent} />}

            {/* Student Table, always visible */}
            <Box
              sx={{
                // backgroundColor:"yellow",
                width:{xs:"100%",sm:"90%",md:"96%"}
              }}
            >
              <StudentTable students={students} column={columns} url={'/register'} />
            </Box>
      </Box>
    </Box>
  );
};

export default StudentManagement;
