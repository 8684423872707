import React, { useState,useEffect } from 'react';
import {
  Box,
  Button,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import GalleryForm from './mediaForm';
import GalleryTable from '../../RecordTable/StickyHeaderTable';

import apiClient from '../../../config/configAxios';

// Sample data for gallery
const imagesColumns = ["S.N","Image Title","Gallery Image","Image Format"];

const videosColumns = ["S.N","Video Title","Video Link"];

const Media = () => {
  const [type, setType] = useState('image'); // Switch between image and video
  const [openForm, setOpenForm] = useState(false);
  const [editData, setEditData] = useState(null);
  //used to store fetched images
  const [fetchImages,setFetchImages] = useState([]);
  //used to store fetched videos
  const [fetchVideos,setFetchVideos] = useState([]);

  const handleTypeChange = (event) => {
    setType(event.target.value);
    setEditData(null); // Clear edit data when switching type
  };

  useEffect(()=>{
    const fetchImageData = ()=>{
      apiClient.get(`/gallery`).then((response)=>{
        setFetchImages(response.data.GalleryDetail)
        // console.log(response);
      }).catch((error)=>{
        console.log(error);
      });
    }
    const fetchVideoData = ()=>{
      apiClient.get(`/videos`).then((response)=>{
        setFetchVideos(response.data.videoDetail)
        // console.log(response);
      }).catch((error)=>{
        console.log(error);
      });
    }
    fetchImageData();
    fetchVideoData();
  },[]);

  const handleEdit = (item) => {
    setEditData(item);
    setOpenForm(true);
  };
  // console.log(fetchVideos);
  return (
    <Box 
      sx={{ 
        marginBottom: 2 ,
        mt:"2",
        // backgroundColor: "green",
        width:{xs:"100%",sm:"90%",md:"96%"},
        
      }}
    >
      <Box sx={{ marginBottom: 2 }}>
        <InputLabel sx={{fontWeight:"600",fontFamily:"calibri"}}>Select Gallery Type</InputLabel>
        <Select value={type} onChange={handleTypeChange} fullWidth
          sx={{
            fontFamily:"calibri",
            width:{xs:"100%",sm:"90%",md:"96%"}
          }}
        >
          <MenuItem value="image" >Image Gallery</MenuItem>
          <MenuItem value="video">Video Gallery</MenuItem>
        </Select>
      </Box>

      <Button variant="contained" fontFamily="Roboto-Serif" onClick={() => setOpenForm(true)}>
        Add New {type === 'image' ? 'Image' : 'Video'}
      </Button>

      {/* Table for displaying images or videos */}
      <Box
        sx={{
          width:{xs:"100%",sm:"90%",md:"96%"}
        }}
      >
          <GalleryTable
            rows={type === 'image' ? fetchImages : fetchVideos}
            column={type === 'image'?imagesColumns : videosColumns}
            url={type === 'image' ? '/gallery' : '/videos'}
            onEdit={handleEdit}
            
          />
      </Box>

      {/* Form for uploading image/video */}
      <GalleryForm
        open={openForm}
        onClose={() => setOpenForm(false)}
        // onSubmit={handleSubmit}
        editData={editData}
        type={type}
      />
    </Box>
  );
};

export default Media;
