
import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import ApiClient from '../../../config/configAxios';
import { toast } from 'react-toastify';

const MediaForm = ({ open, onClose, editData, type }) => {
  const [title, setTitle] = useState('');
  const [file, setFile] = useState(null);
  const [videoLink, setVideoLink] = useState('');

  // Set form values if editing
  useEffect(() => {
    // console.log("Edit Data from media form.",editData);
    if (editData) {
      setTitle(editData.image_title || editData.video_title);
      setVideoLink(editData.video_link || '');
      setFile(null); // Clear file for editing
    }
  }, [editData]);

  const handleFileChange = (e) => setFile(e.target.files[0]);

  const handleSubmit = () => {
    const formData = {
      title,
      file,
      videoLink: type === 'video' ? videoLink : undefined
    };
    // console.log("On editing data:",formData);

    const submitImageData = new FormData();
    submitImageData.append("image_title",formData.title);
    submitImageData.append("gallery_image",formData.file);

    const submitVideoData = {
      "video_title":title,
      "video_link":videoLink
    }
    // console.log("Don't know :",type);
    if(!editData){
      if(type==='image'){
        ApiClient.post(`/gallery`,submitImageData,{
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then((response)=>{
          // console.log(response);
          if(response.data.success){
            toast.success("Photo Added.",{
              position: "top-right",
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }else{
            toast.error("Sorry, Something wrong.",{
              position: "top-right",
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }).catch((error)=>[
          console.log(error)
        ]);
      }
      if(type==='video'){
        ApiClient.post(`/videos`,submitVideoData).then((response)=>{
          // console.log(response);
          if(response.data.success){
            toast.success("Video Added.",{
              position: "top-right",
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }else{
            toast.error("Sorry, Something wrong.",{
              position: "top-right",
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }).catch((error)=>[
          console.log(error)
        ]);
      }
    }else{
      if(type==='image'){
        ApiClient.patch(`/gallery/${editData.id}`,submitImageData,{
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then((response)=>{
          // console.log(response);
          if(response.data.success){
            toast.success("Photo updated.",{
              position: "top-right",
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }else{
            toast.error("Sorry, Something wrong.",{
              position: "top-right",
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }).catch((error)=>[
          console.log(error)
        ]);
      }
      if(type==='video'){
        ApiClient.patch(`/videos/${editData.id}`,submitVideoData).then((response)=>{
          // console.log(response);
          if(response.data.success){
            toast.success("Video Updated.",{
              position: "top-right",
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }else{
            toast.error("Sorry, Something wrong.",{
              position: "top-right",
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }).catch((error)=>[
          console.log(error)
        ]);
      }
    }
    // onSubmit(formData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{type === 'image' ? 'Upload Image' : 'Upload Video'}</DialogTitle>
      <DialogContent>
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        {type === 'image' ? (
          <input type="file" onChange={handleFileChange} accept="image/*" />
        ) : (
          <TextField
            label="Video Link"
            variant="outlined"
            fullWidth
            value={videoLink}
            onChange={(e) => setVideoLink(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MediaForm;
