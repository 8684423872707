import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

import Grid from '@mui/material/Grid2';
const DashboardCard = ({ title, value }) => (
  <Grid item xs={12} sm={6} md={3} >
    <Card sx={{
      height:"auto",
      width:"100%"
    }}>
      <CardContent>
        <Typography variant="h6" sx={{
          fontFamily:'Roboto-Serif',
          fontSize:{xs:'0.8rem',sm:'1.2rem',md:"1.6rem"}
        }}>
          {title}
        </Typography>
        <Typography variant="h4" color="primary"
          sx={{
            fontFamily:'Roboto-Serif',
            fontSize:{xs:'0.7rem',sm:'1.2rem',md:"1.6rem"}
          }}
        >
          {value}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);

export default DashboardCard;