import React from 'react';
import { Typography } from '@mui/material';

const courseImage = ({ courseImage, setCourseImage }) => {
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setCourseImage(file);
  };

  return (
    <div>
      <Typography variant="h6">Course Image</Typography>
      <input type="file" onChange={handleFileChange} />
    </div>
  );
};

export default courseImage;
