
import React, { useState } from 'react';
import { Box,  MenuItem, Select, InputLabel } from '@mui/material';

import ServiceForm from './Services/servicesForm';
import ServiceTable from './Services/servicesTable';
import TeamMembers from './TeamMember/member.jsx';
import University from './Programs/parentUniversity.jsx';

const AboutUs = () => {
  const [type, setType] = useState('service');
  const [services, setServices] = useState([]);
  const [isFormVisible, setIsFormVisible] = useState(false); // Toggle form visibility
  const [editData, setEditData] = useState(null); // State for editing data

  const handleTypeChange = (e) => {
    setType(e.target.value);
    setIsFormVisible(false); // Close any open forms when switching types
  };

  const handleSubmit = (formData) => {
    if (type === 'service') {
      setServices([...services, { sid: services.length + 1, sTitle: formData.title, sDetail: formData.detail }]);
    }
    setIsFormVisible(false); // Hide form after submission
    setEditData(null); // Reset edit data
  };

  const handleEdit = (item) => {
    setEditData(item);
    setIsFormVisible(true); // Show the form for editing
  };

  const handleDelete = (id) => {
    if (type === 'service') {
      setServices(services.filter((item) => item.sid !== id));
    }
  };

  // const handleAddNew = () => {
  //   setEditData(null); // Reset edit data when adding a new item
  //   setIsFormVisible(true); // Show the form
  // };

  return (
    <Box sx={{ 
      
      padding: 4 ,
      // backgroundColor:"red",
      }}>
      {/* Select Type */}
      <InputLabel>Select Type</InputLabel>
      <Select value={type} onChange={handleTypeChange} fullWidth 
        sx={{ 
          marginBottom: 4,
          // backgroundColor:"green",
          width:{xs:"100%",sm:"90%",md:"96%"},
        }}>
        <MenuItem value="service" >Service</MenuItem>
        <MenuItem value="teamMember">Team Member</MenuItem>
        <MenuItem value="universityProgram">University Programs</MenuItem>
      </Select>
      {/* Render Based on Selected Type */}
      {type === 'service' && (
        <>
          {isFormVisible && (
            <ServiceForm onSubmit={handleSubmit} editData={editData} onCancel={() => setIsFormVisible(false)} />
          )}
          <ServiceTable data={services} onEdit={handleEdit} onDelete={handleDelete} />
        </>
      )}
      {type === 'teamMember' && <TeamMembers />}
      {type === 'universityProgram' && <University />}
    </Box>
  );
};

export default AboutUs;