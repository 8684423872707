import React from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input
} from '@mui/material';
import ApiClient from '../../../../config/configAxios';
import { toast } from 'react-toastify';
const TeamMemberForm = ({
  open,
  setOpen,
  formData,
  setFormData,
  editIndex,
}) => {
  // console.log("what's inside index:",editIndex);
  // Handle input field changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle file input change for photo
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // console.log("this is file")
    if (file) {
      setFormData({ ...formData, member_photo: file });
    }
  };

  // Handle form submission
  const onSubmit = () => {
    // console.log("Form Submitted with Data:", formData); 
    const submitData = new FormData();
    submitData.append("member_name",formData.member_name);
    submitData.append("desig",formData.desig);
    submitData.append("member_words",formData.member_words);
    submitData.append("member_photo",formData.member_photo);
    // console.log("after adding add to ",submitData);
    if(editIndex === null){
      ApiClient.post(`/teamMember`,submitData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response)=>{
        // console.log(response);
        if(response.data.success){
          toast.success("Member Added.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }else{
          toast.error("Sorry, Something wrong.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }).catch((error)=>{
        console.log(error);
      });
    }else{
      ApiClient.patch(`/teamMember/${formData.id}`,submitData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response)=>{
        // console.log(response);
        if(response.data.success){
          toast.success("Member Detail Updated.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }else{
          toast.error("Sorry, Something wrong.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }).catch((error)=>{
        console.log(error);
      });
      // console.log("this is edition")
    }
    setOpen(false); // Close the dialog
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        {editIndex !== null ? "Edit Team Member" : "Add New Team Member"}
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Member Name"
          name="member_name"
          value={formData.member_name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Designation"
          name="desig"
          value={formData.desig}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Member Words"
          name="member_words"
          value={formData.member_words}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        {/* File input for photo upload */}
        <Input
          type="file"
          onChange={handleFileChange}
          fullWidth
          margin="normal"
        />
        {/* {formData.member_photo && (
          <Box>
            <Typography>Selected Photo:</Typography>
            <img
              src={formData.member_photo}
              alt="Member"
              sx={{ width: 100, height: 100 }}
            />
          </Box>
        )} */}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
          {editIndex !== null ? "Save Changes" : "Add Member"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeamMemberForm;
