import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import ApiClient from '../../../../config/configAxios';
import { toast } from 'react-toastify';

const ServiceForm = ({ open, onClose, editData }) => {
  const [title, setTitle] = useState('');
  const [detail, setDetail] = useState('');
  const [courseType, setCourseType] = useState('');

  useEffect(() => {
    if (editData) {
      setTitle(editData.sTitle || '');
      setDetail(editData.sDetail || '');
      setCourseType(editData.whichCourse || '');
    } else {
      setTitle('');
      setDetail('');
      setCourseType('');
    }
  }, [editData]);

  const handleSubmit = () => {
    const formData = {
      sTitle: title,
      sDetail: detail,
      whichCourse: courseType,
    };
    if(editData){
      setTitle(editData.sTitle || '');
      setDetail(editData.sDetail || '');
      setCourseType(editData.whichCourse || '');
      
      ApiClient.patch(`/services/${editData.id}`,formData).then((response)=>{
        // console.log(response);
        if(response.data.success){
          toast.success("Service Updated.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }else{
          toast.error("Sorry, Something wrong.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }).catch((error)=>{
        console.log(error);
      });
    }else{
      // console.log("I am adding new record");
      ApiClient.post(`/services`,formData).then((response)=>{
        console.log(response);
        if(response.data.success){
          toast.success("Service Added.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }else{
          toast.error("Sorry, Something wrong.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }).catch((error)=>{
        console.log(error);
      });
    }
    // console.log('Form Data:', formData); // Console log the form data
    handleClose();
  };

  const handleClose = () => {
    setTitle(''); // Reset title
    setDetail(''); // Reset detail
    setCourseType(''); // Reset course type
    onClose(); // Close the dialog
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{editData ? 'Edit Service' : 'Add Service'}</DialogTitle>
      <DialogContent>
        <TextField
          label="Service Title"
          variant="outlined"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Service Detail"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={detail}
          onChange={(e) => setDetail(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="course-type-label">Course Type</InputLabel>
          <Select
            labelId="course-type-label"
            value={courseType}
            onChange={(e) => setCourseType(e.target.value)}
            label="Course Type"
          >
            <MenuItem value="Study Abroad">Study Abroad</MenuItem>
            <MenuItem value="Study In Nepal">Study IN Nepal</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSubmit}>
          {editData ? 'Update' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceForm;
