import React, { useEffect } from 'react';
import { Container } from "@mui/material";
import DashboardTabs from '../Dashboard/dash_board';
import { useNavigate } from 'react-router-dom';
const AdminDashboardPage = ()=>{
    const navigate = useNavigate();

    useEffect(() => {
      // Check if the auth token exists
      const token = localStorage.getItem('login_token');
    //   console.log("Isn't this token?",token);
      if (!token) {
        // Redirect to login if no token is found
        navigate('/');
      }
    }, [navigate]);
    return(
        <Container maxWidth={false}
            disableGutters
            sx={{
                // backgroundColor: "red",
                // padding : "80px",
                // border: '1px solid black'
            }}
        >
            <DashboardTabs />
        </Container>
        
    );
}
export default AdminDashboardPage;