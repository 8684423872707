import React, { useState } from "react";
import {Box,Typography} from '@mui/material'
import FilterForm from "./filter_form";
import FilteredRecordTable from "./FilteredRecord";

const columns = ["S.N","Student Name",
    "Email","Contact","Address","Graduated College","Admission On","Qualification",
    "Destination","Preparation Test","Admitted College","Program","Feedback","Alumini"
  ];
const FilterSection = ()=>{
    const [filteredRecord,setFilteredRecord] = useState([])
    return(
        <Box>
            <Typography variant="h4" fontWeight='600' sx={{fontSize:{xs:"1.2rem",sm:"1.5rem",md:"2rem"},mt:2}} gutterBottom>Filter Record</Typography>
            <FilterForm setFilteredRecord={setFilteredRecord} />
            {
                filteredRecord.length > 0 ? (
                    <Box
                        sx={{
                            width:{xs:"100%",sm:"96%",md:"90%"}
                        }}
                    >
                        <Typography variant="h4" fontWeight='600' sx={{fontSize:{xs:"1.2rem",sm:"1.5rem",md:"2rem"},mt:2}} gutterBottom>Download Record</Typography>
                        <FilteredRecordTable rows={filteredRecord} column={columns}/>
                    </Box>
                ):''
            }
        </Box>
    )
}
export default FilterSection;