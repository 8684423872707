import React, { useState, useEffect } from 'react';
import { TextField, Button, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ApiClient from '../../../config/configAxios';
import { toast } from 'react-toastify';

const CountryForm = ({ hideForm, editData }) => {
  const [formData, setFormData] = useState({
    countryName: '',
    admissionGuideline: [''],
    reasonToChoose: '',
    popularUniversities: [''],
    faq: [{ faqQuestion: '', faqAnswer: '' }],
    flag: null,
  });

  useEffect(() => {
    if (editData) {
      setFormData({
        countryName: editData.countryName || '',
        admissionGuideline: editData.admissionGuideline || [''],
        reasonToChoose: editData.reasonToChoose || '',
        popularUniversities: editData.popularUniversities || [''],
        faq: editData.faq || [{ faqQuestion: '', faqAnswer: '' }],
        flag: null,
      });
    }
  }, [editData]);

  const handleChange = (e, index, type) => {
    const { name, value, files } = e.target;

    if (name === 'flag' && files.length > 0) {
      setFormData({
        ...formData,
        flag: files[0],
      });
    } else if (name === 'faqQuestion' || name === 'faqAnswer') {
      const updatedFaq = [...formData.faq];
      updatedFaq[index][name] = value;
      setFormData({
        ...formData,
        faq: updatedFaq,
      });
    } else if (type === 'admissionGuideline') {
      const updatedGuidelines = [...formData.admissionGuideline];
      updatedGuidelines[index] = value;
      setFormData({ ...formData, admissionGuideline: updatedGuidelines });
    } else if (type === 'popularUniversities') {
      const updatedUniversities = [...formData.popularUniversities];
      updatedUniversities[index] = value;
      setFormData({
        ...formData,
        popularUniversities: updatedUniversities,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleAddFaq = () => {
    setFormData({
      ...formData,
      faq: [...formData.faq, { faqQuestion: '', faqAnswer: '' }],
    });
  };

  const handleDeleteFaq = (index) => {
    const updatedFaq = formData.faq.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      faq: updatedFaq,
    });
  };

  const handleAddUniversity = () => {
    setFormData({
      ...formData,
      popularUniversities: [...formData.popularUniversities, ''],
    });
  };

  const handleDeleteUniversity = (index) => {
    const updatedUniversities = formData.popularUniversities.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      popularUniversities: updatedUniversities,
    });
  };

  // Handle adding a new item to a specific array (admissionGuideline, popularUniversities, etc.)
  const handleAddItem = (type) => {
    if (type === 'admissionGuideline') {
      setFormData({
        ...formData,
        admissionGuideline: [...formData.admissionGuideline, ''],
      });
    } else if (type === 'popularUniversities') {
      setFormData({
        ...formData,
        popularUniversities: [...formData.popularUniversities, ''],
      });
    }
  };

  const handleDeleteItem = (index, type) => {
    if (type === 'admissionGuideline') {
      const updatedGuidelines = formData.admissionGuideline.filter((_, i) => i !== index);
      setFormData({
        ...formData,
        admissionGuideline: updatedGuidelines,
      });
    } else if (type === 'popularUniversities') {
      const updatedUniversities = formData.popularUniversities.filter((_, i) => i !== index);
      setFormData({
        ...formData,
        popularUniversities: updatedUniversities,
      });
    }
  };

  const handleSubmit = () => {
    if (!formData.flag) {
      toast.error('Flag is required!', {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return;
    }

    const submissionData = new FormData();
    submissionData.append('countryName', formData.countryName);
    submissionData.append('admissionGuideline', JSON.stringify(formData.admissionGuideline));
    submissionData.append('reasonToChoose', formData.reasonToChoose);
    submissionData.append('faq', JSON.stringify(formData.faq));
    submissionData.append('popularUniversities', JSON.stringify(formData.popularUniversities));
    submissionData.append('flag', formData.flag);

    const request = editData 
      ? ApiClient.patch(`/destinedCountry/${editData.id}`, submissionData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      : ApiClient.post('/destinedCountry', submissionData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

    request.then((response) => {
      if (response.data.success) {
        toast.success(editData ? 'Country updated.' : 'Country Added.', {
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        toast.error('Sorry, Something wrong.', {
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    }).catch((err) => {
      console.log(err);
      toast.error('An error occurred. Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    });

    setFormData({
      countryName: '',
      admissionGuideline: [''],
      reasonToChoose: '',
      popularUniversities: [''],
      faq: [{ faqQuestion: '', faqAnswer: '' }],
      flag: null,
    });
    hideForm();
  };

  return (
    <Grid container spacing={2} direction="column" sx={{ maxWidth: 400, padding: '20px' }}>
      <Grid item>
        <TextField
          label="Country Name"
          name="countryName"
          value={formData.countryName}
          onChange={handleChange}
          fullWidth
          required
        />
      </Grid>

      {formData.admissionGuideline.map((guideline, index) => (
        <Grid item key={index}>
          <TextField
            label={`Admission Guideline ${index + 1}`}
            value={guideline}
            onChange={(e) => handleChange(e, index, 'admissionGuideline')}
            fullWidth
            required
            InputProps={{
              endAdornment: (
                <>
                  <IconButton onClick={() => handleAddItem('admissionGuideline')} size="small" style={{ marginRight: '8px' }}>
                    <AddIcon />
                  </IconButton>
                  {formData.admissionGuideline.length > 1 && (
                    <IconButton onClick={() => handleDeleteItem(index, 'admissionGuideline')} size="small">
                      <DeleteIcon />
                    </IconButton>
                  )}
                </>
              ),
            }}
          />
        </Grid>
      ))}

      <Grid item>
        <TextField
          label="Reason To Choose"
          name="reasonToChoose"
          value={formData.reasonToChoose}
          onChange={handleChange}
          fullWidth
          required
        />
      </Grid>

      {formData.popularUniversities.map((university, index) => (
        <Grid item key={index}>
          <TextField
            label={`Popular University ${index + 1}`}
            value={university}
            onChange={(e) => handleChange(e, index, 'popularUniversities')}
            fullWidth
            required
            InputProps={{
              endAdornment: (
                <>
                  <IconButton onClick={handleAddUniversity} size="small" style={{ marginRight: '8px' }}>
                    <AddIcon />
                  </IconButton>
                  {formData.popularUniversities.length > 1 && (
                    <IconButton onClick={() => handleDeleteUniversity(index)} size="small">
                      <DeleteIcon />
                    </IconButton>
                  )}
                </>
              ),
            }}
          />
        </Grid>
      ))}

      {formData.faq.map((faq, index) => (
        <Grid item key={index}>
          <TextField
            label={`FAQ Question ${index + 1}`}
            name="faqQuestion"
            value={faq.faqQuestion}
            onChange={(e) => handleChange(e, index, 'faq')}
            fullWidth
            required
            InputProps={{
              endAdornment: (
                <>
                  <IconButton onClick={handleAddFaq} size="small" style={{ marginRight: '8px' }}>
                    <AddIcon />
                  </IconButton>
                  {formData.faq.length > 1 && (
                    <IconButton onClick={() => handleDeleteFaq(index)} size="small">
                      <DeleteIcon />
                    </IconButton>
                  )}
                </>
              ),
            }}
          />
          <TextField
            label={`FAQ Answer ${index + 1}`}
            name="faqAnswer"
            value={faq.faqAnswer}
            onChange={(e) => handleChange(e, index, 'faq')}
            fullWidth
            required
          />
        </Grid>
      ))}

      <Grid item>
        <input
          type="file"
          name="flag"
          onChange={handleChange}
          accept="image/*"
          style={{ width: '100%' }}
          required
        />
      </Grid>

      <Grid item>
        <Button variant="contained" onClick={handleSubmit} fullWidth>
          {editData ? 'Update Country' : 'Add Country'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default CountryForm;
