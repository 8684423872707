import React, { useState,useEffect} from 'react';
import {
  Box,
  Button,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import NewsForm from './News/newsForm';
// import NewsTable from './News/newsTable';
import NoticeForm from './Notices/noticeForm';
// import NoticeTable from './Notices/noticeTable';
import ApiClient from '../../config/configAxios.jsx'
import RecordTable from '../RecordTable/StickyHeaderTable';
// Sample data for news and notices
const columns = ["S.N","Title","Published On","Description","Banner","Image Format"];


const Information = () => {
  const [type, setType] = useState('news'); // Switch between news and notices
  const [openForm, setOpenForm] = useState(false);
  const [editData, setEditData] = useState(null);
  const [news, setNews] = useState([]);
  const [notices, setNotices] = useState([]);

  const handleTypeChange = (event) => {
    setType(event.target.value);
    setEditData(null); // Clear edit data when switching type
  };
  const handleEdit = (item) => {
    setEditData(item);
    setOpenForm(true); // Open the form for editing
  };

  useEffect(()=>{
    const fetchNews = ()=>{
      ApiClient.get(`/news`).then((response)=>{
        setNews(response.data.newsDetail);
      }).catch((error)=>{
        console.log(error);
      });
    }
    const fetchNotices = ()=>{
      ApiClient.get(`/notices`).then((response)=>{
        setNotices(response.data.noticeDetail);
      }).catch((error)=>{
        console.log(error);
      });
    }
    fetchNews();
    fetchNotices();
  },[]);
const handleClose = ()=>{
  setEditData(null);
  setOpenForm(false)
}
  return (
    <Box 
    sx={{ 
      padding: {sm:1,md:2,lg:4},
      // backgroundColor:"red",
      width: "100%"
    }}>
      <Box 
        sx={{ 
          marginBottom: 2 ,
          mt:"2",
          // backgroundColor: "green",
          width:{xs:"100%",sm:"96%"}
        }}
        >
        <InputLabel>Select Type</InputLabel>
        <Select value={type} onChange={handleTypeChange} fullWidth>
          <MenuItem value="news">News</MenuItem>
          <MenuItem value="notice">Notice</MenuItem>
        </Select>
      </Box>

      <Button variant="contained" onClick={() => setOpenForm(true)}>
        Add New {type === 'news' ? 'News' : 'Notice'}
      </Button>
      <Box 
        sx={{
          backgroundColor: "yellow",
          width:{xs:"100%",sm:"90%",md:"94%"}
        }}
      >
        {/* Table for displaying news or notices */}
        {type === 'news' ? (
          <RecordTable rows={news} column={columns} url={'/news'} onEdit={handleEdit} />
        ) : (
          <RecordTable rows={notices} column={columns} url={'/notices'} onEdit={handleEdit} />
        )}
      </Box>
      
        {/* Form for adding news or notice */}
        {type === 'news' ? (
          <NewsForm open={openForm} onClose={handleClose} editData={editData} />
        ) : (
          <NoticeForm open={openForm} onClose={handleClose} editData={editData} />
        )}
      
    </Box>
  );
};

export default Information;
