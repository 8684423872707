import React from 'react';
import './App.css';
import DashboardPage from './pages/admin_dashboard.page';
import LoginForm from './Component/login_form/login_form';
import { 
  createBrowserRouter, 
  createRoutesFromElements, 
  RouterProvider, 
  Route 
} from 'react-router-dom';
import { ToastContainer,Flip} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>

          <Route path='/' element={<LoginForm />} />
          
          <Route path='/dashboard' element={<DashboardPage />} />
      </Route>
    )
  );
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition= {Flip}
      />
    </>
  );
}
export default App;