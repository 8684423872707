import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { createTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';
import InfoIcon from '@mui/icons-material/Info';
import PublicIcon from '@mui/icons-material/Public';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LogoutIcon from '@mui/icons-material/Logout';

import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import DashboardContent from './dashboard.content.component';
import StudentManagement from '../Component/Pages/Students/studentManagement';
import CourseManagement from '../Component/Form/courseManagement';
import CountryInfo from '../Component/Pages/Country/countryInfo';
import Information from '../Component/News-Notices/information';
import Media from '../Component/Pages/gallery/media';
import EventInformation from '../Component/Pages/Event/eventInformation'; 
import AboutUs from '../Component/Pages/Aboutus/aboutUs';

import { useNavigate } from 'react-router-dom';

const NAVIGATION = [
  {
    segment: 'dashboard',
    title: 'Dashboard',
    icon: <DashboardIcon />,
  },
  {
    segment: 'student-management',
    title: 'Student Management',
    icon: <PeopleIcon />,
  },
  {
    segment: 'course-management',
    title: 'Course Management',
    icon: <SchoolIcon />,
  },
  {
    segment: 'country-info',
    title: 'Country Info',
    icon: <PublicIcon />, 
  },
  {
    segment: 'news-notices',
    title: 'News and Notices',
    icon: <NewspaperIcon />, 
  },
  {
    segment: 'gallery',
    title: 'Gallery',
    icon: <CameraAltIcon />, 
  },
  {
    segment: 'event',
    title: 'Event',
    icon: <EventAvailableIcon />,
  },
  {
    segment: 'about-us',
    title: 'About Us',
    icon: <InfoIcon />,
  },
  {
    segment: 'logout',
    title: 'Logout',
    icon: <LogoutIcon />,
  },
];

const demoTheme = createTheme({
    components: {
      MuiAppProvider: {
        styleOverrides: {
          brandingTitle: {
            backgroundColor: 'lightblue', // Custom default background for the title
            color: 'white',              // Custom text color for the title
            padding: '8px',              // Optional padding to make it look better
            
          },
        },
      },
    },
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: { 
    light: true,
    dark: true
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});
const DashboardComponent = (path)=>{
  switch(path){
    case '/dashboard':
      return <DashboardContent />
    case '/student-management':
      return <StudentManagement />
    case '/course-management':
      return <CourseManagement />
    case '/country-info':
      return <CountryInfo />;
    case '/gallery':
      return < Media/>;
    case '/news-notices':
      return <Information/>;
    case '/event':  
      return <EventInformation />; 
    case '/about-us':
      return <AboutUs />
    default:
      return <DashboardContent/>
  }
}

function DemoPageContent({ pathname }) {
  return (
    <Box
      sx={{
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        height: "100vh",
        position:"fixed",
        padding:"10px",
        overflowY: "scroll"
      }}
    >
      {
        DashboardComponent(pathname)
      }
    </Box>
  );
}

DemoPageContent.propTypes = {
  pathname: PropTypes.string.isRequired,
};

function DashboardTabs(props) {
  const { window } = props;
  // console.log("Print props directly:",props.setLoginToken);
  // console.log("Print props using window:",window);
  const navigate = useNavigate();
  const [pathname, setPathname] = React.useState('/dashboard');
  if(pathname === '/logout'){
    localStorage.removeItem('login_token');
    navigate('/')
  }
  const router = React.useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: (path) => setPathname(String(path)),
    };
  }, [pathname]);

  // Remove this const when copying and pasting into your project.
  const demoWindow = window !== undefined ? window() : undefined;

  return (
    <AppProvider
      navigation={NAVIGATION}
      router={router}
      theme={demoTheme}
      window={demoWindow}
      branding={{
        logo: '',
        title: 'Admin Dashboard',
      }}
    >
      {/* Pass the title prop here */}
      <DashboardLayout 
      >
        <DemoPageContent pathname={pathname} />
      </DashboardLayout>
    </AppProvider>
  );
}

DashboardTabs.propTypes = {
  window: PropTypes.func,
};

export default DashboardTabs;
