import React, { useState, useEffect } from 'react';
import ApiClient from '../../../config/configAxios';
import { toast } from 'react-toastify';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 
} from '@mui/material';

const NewsForm = ({ open, onClose, editData }) => {
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [detail, setDetail] = useState('');
  const [photo, setPhoto] = useState(null);
  console.log("what is the edit value:",editData);

  useEffect(() => {
    if (editData) {
      console.log("changing .............")
      setTitle(editData.news_title);
      setDate(editData.news_date);
      setDetail(editData.news_detail);
      setPhoto(editData.news_photo || null);
    }
  }, [editData]);

  const handleFileChange = (e) => {
    setPhoto(e.target.files[0]);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("news_title",title);
    formData.append("news_date",date);
    formData.append("news_detail",detail);
    formData.append("news_photo",photo);
    if(editData===null){
      ApiClient.post(`/news`,formData,{
        headers:{
          "Content-Type":'multipart/form-data'
        }
      }).then((response)=>{
        // console.log(response);
        if(response.data.success){
          toast.success("New News Inserted.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }else{
          toast.error("Sorry, Record Not Inserted.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }).catch((error)=>{
        console.log(error)
      });
    }else{
      console.log(formData)
      ApiClient.patch(`/news/${editData.id}`,formData,{
        headers:{
          "Content-Type":'multipart/form-data'
        }
      }).then((response)=>{
        if(response.data.success){
          toast.success("Record Updated.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
          
        }else{
          toast.error("Sorry, Record Not Updated.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }).catch((error)=>{
        console.log(error)
      });

    }

    // console.log('Form Data:', formData);  // Log form data to the console
    setTitle('');
    setDate('');
    setDetail('');
    setPhoto(null);
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      sx={{ 
        // width: '600px', 
        margin: 'auto',  // Ensures dialog is centered horizontally
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: "12px",
        padding: "2px"
      }}
    >
      <DialogTitle>{editData ? 'Edit News' : 'Add News'}</DialogTitle>
      <DialogContent sx={{ minWidth: '300px',fontSize: "12px" }}>

        {/* File Input for Photo */}
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ fontSize:"12px",marginBottom: 2 }}
        />
         <TextField
          type="file"
          fullWidth
          onChange={handleFileChange}
          InputLabelProps={{ shrink: true }}
          sx={{ fontSize: '12px', marginBottom: 2 }}
        />
        
        {/* Using Grid2 for layout */}
        <Grid2 container spacing={2}>
          <Grid2 item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <TextField
              label="News On"
              type="date"
              variant="outlined"
              fullWidth
              name="news_on"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
              InputLabelProps={{
                shrink: true
              }}
              sx={{ fontSize:"12px",marginBottom: '16px' }}
            />
          </Grid2>
        </Grid2>

        <TextField
          label="Details"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={detail}
          onChange={(e) => setDetail(e.target.value)}
          sx={{ fontSize:"12px",marginBottom: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewsForm;