import React, { useEffect, useState } from 'react';
import {Button,Box } from '@mui/material';
import CountryForm from './countryForm';
import CountryTable from '../../RecordTable/StickyHeaderTable';
import ApiClient from '../../../config/configAxios';
const CountryInfo = () => {
  const column =["S.N","Country Name","Guidelines","Reason To Choose","FAQ","Universities","Banner","File Format"];
  const [countries, setCountries] = useState([]);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editData,setEditData] = useState(null)
  

  // Update a country
  const handleUpdateCountry = (updatedCountry) => {
    // console.log("country after to edit: ",updatedCountry);
    setEditData(updatedCountry);
    setIsFormVisible(true);
    // setCountries(updatedCountries);
  };

  useEffect(()=>{
    const fetchCountryRecord = ()=>{
      ApiClient.get('/destinedCountry').then((response)=>{
        console.log(response.data.CountryDetail);
        console.log(response.data.CountryDetail[0].admissionGuideline);
        console.log(Array.isArray(response.data.CountryDetail[0].admissionGuideline));
        setCountries(response.data.CountryDetail);
      }).catch((error)=>{
        console.log(error)
      })
    }
    fetchCountryRecord();
  },[])
  return (
    <Box
      sx={{
        // backgroundColor: "red",
        display: "flex",
        flexDirection:"column",
        // justifyContent:"center",
        alignItems: "center",
        height: "auto",
      }}
      >
     <Box
      sx={{
        textAlign: "center",
      }}
     >
        <Button onClick={() => setIsFormVisible(!isFormVisible)} variant='contained'>
            {isFormVisible ? 'Cancel' : 'Add'}
          </Button>

          <Box
            sx={{
              border: "1px solid gray",
              mt: '20px',
            }}
          >
            {isFormVisible && (
              <CountryForm
                hideForm={() => setIsFormVisible(false)}
                editData = {editData}
              />
            )}
          </Box>
     </Box>
       <Box
        sx={{
          // backgroundColor:"yellow",
          width: "100%",
          margin: "10px 90px 40px 90px",
        }}
      >
      <CountryTable rows={countries} column={column} url={'/destinedCountry'} onEdit={handleUpdateCountry}/>
      </Box>
    </Box>
  );
};

export default CountryInfo;
