import React, { useState,useEffect } from 'react';
import { Container, Box, Button, Typography } from '@mui/material';
import CourseForm from '../Nepal/courseForm';
import AbroadCourseTable from '../../../RecordTable/StickyHeaderTable';
import ApiClient from '../../../../config/configAxios';
import { toast } from 'react-toastify';

const columns =["S.N","Course Title","Overview","Preparation Tips","FAQ","Test Format","Cover Image","Image Format"];
const AbroadCourse = () => {
  const [courseData, setCourseData] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false); 
  const [currentCourse, setCurrentCourse] = useState(null); 

  useEffect(()=>{
    const fetchAbroadCourses = ()=>{
      ApiClient.get('/abroadcourses').then((response)=>{
        setCourseData(response.data.AbroadCourse);
        // console.log(response.data.AbroadCourse);
      }).catch((error)=>{
        console.log(error);
      });
    }
    fetchAbroadCourses();
  },[]);

  // Handle form submission to add or update the course
  const handleFormSubmit = (data) => {
    // Log the data object containing form field values
    // console.log("Form Submitted with the following data:", data);
    const submitAbroadCourse = new FormData();
    submitAbroadCourse.append("courseTitle",data.courseTitle);
    submitAbroadCourse.append("overview",data.overview);
    submitAbroadCourse.append("preparationTips",JSON.stringify(data.preparationTips));
    submitAbroadCourse.append("faq",JSON.stringify(data.faq));
    submitAbroadCourse.append("testFormat",JSON.stringify(data.testFormat));
    submitAbroadCourse.append("courseImage",data.courseImage);

    if(isEditing){
      ApiClient.patch(`/abroadcourses/${currentCourse.id}`,submitAbroadCourse,{
        headers:{
          "Content-Type":'multipart/form-data'
        }
      }).then((response)=>{
        if(response.data.success){
          toast.success("Abroad Course Updated.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }else{
          toast.error("Sorry, Something wrong.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }).catch((error)=>{
        console.log(error);
      })
    }else{
      ApiClient.post(`/abroadcourses`,submitAbroadCourse,{
        headers:{
          "Content-Type":'multipart/form-data'
        }
      }).then((response)=>{
        // console.log(response);
        if(response.data.success){
          toast.success("Abroad Course Added.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }else{
          toast.error("Sorry, Something wrong.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }).catch((error)=>{
        console.log(error);
      })
    }

    
    setIsFormOpen(false); // Close the form after submission
    setIsEditing(false);  // Reset editing state
    setCurrentCourse(null); // Reset current course data
  };

  // Handle editing a course
  const handleEdit = (course) => {
    setIsFormOpen(true);  // Show the form
    setIsEditing(true);   // Set editing mode
    setCurrentCourse(course); // Set the course to be edited
  };

  // Handle adding a new course
  const handleAddNew = () => {
    setIsFormOpen(true);  // Show the form
    setIsEditing(false);  // Set to add new course mode
    setCurrentCourse(null); // Clear any course data
  };

  // Handle canceling the form
  const handleCancelForm = () => {
    setIsFormOpen(false); // Close the form
    setIsEditing(false);  // Reset editing mode
    setCurrentCourse(null); // Reset course data on cancel
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        // backgroundColor:"green",
        height: "100%",
        paddingBottom:"20px",
        textAlign: "center",
      }}
    >
      {/* <Typography variant="h6" gutterBottom>
        Abroad Course Form
      </Typography> */}

      {/* Only show Add New Course button when form is not open */}
      {!isFormOpen && (
        <Button onClick={handleAddNew} variant="contained" sx={{ marginBottom: 2 }}>
          Add New Course
        </Button>
      )}

      {/* Show the form if isFormOpen is true */}
      {isFormOpen && (
        <Box sx={{ marginBottom: 3 }}>
          <CourseForm
            onSubmit={handleFormSubmit} // Handle the form submission
            initialData={currentCourse} // Pre-fill data if editing
            onCancel={handleCancelForm} // Cancel form action
            isAbroadCourse={true} // Pass isAbroadCourse flag for abroad course
          />
        </Box>
      )}

      {/* Table of courses */}
      <Box
        sx={{
          mt:3,
          width:{xs:'100%',sm:'94%',md:"96%",lg:"100%"}
        }}
      >
        {
          courseData.length > 0 ? (
            <AbroadCourseTable
              rows={courseData}
              column={columns}
              url={"/abroadcourses"}
              onEdit={handleEdit}
            />
          ):(
            <Typography
            variant='h4'
              sx={{
                fontFamily: "calibri"
              }}
            >
              Data Not Available
            </Typography>
          )
        }
      </Box>
    </Container>
  );
};

export default AbroadCourse;
