import React, { useEffect, useState } from 'react';
import { Button, Box} from '@mui/material';
import UniversityProgramsForm from './universityForm';
import UniversityProgramsTable from '../../../RecordTable/StickyHeaderTable';
import ApiClient from '../../../../config/configAxios';

const University = () => {
  const columns = ["S.N","University Name","Programs"];

  const [openForm, setOpenForm] = useState(false); // State to control dialog visibility
  const [editData, setEditData] = useState(null); // State to hold data for editing
  const [programs,setPrograms] = useState([]);
  const handleAddNew = () => {
    setEditData(null); // Clear edit data
    setOpenForm(true); // Open the dialog
  };

  const handleEdit = (data) => {
    setEditData(data); // Set data for editing
    setOpenForm(true); // Open the dialog
  };

  useEffect(()=>{
    const fetchPrograms = () =>{
      ApiClient.get('/universityprograms').then((response)=>{
        // console.log(response);
        setPrograms(response.data.universityDetail);
      }).catch((error)=>{
        console.log(error)
      });
    }
    fetchPrograms();
  },[]);

  return (
    <Box 
      sx={{ 
        width:{xs:"100%",sm:"90%",md:"96%"}
      }}>
      <Button variant="contained" color="primary" onClick={handleAddNew}
        sx={{
          mb:2
        }}
      >
        Add New University Program
      </Button>

      <UniversityProgramsTable
        rows={programs} // Pass the state
        column={columns}
        url={'/universityprograms'}
        onEdit={handleEdit}
      />

      <UniversityProgramsForm
        open={openForm}
        onClose={() => setOpenForm(false)}
        editData={editData}
      />
    </Box>
  );
};

export default University;