import React, { useState } from 'react';
import NepalCourseForm from './Course/Nepal/nepalCoursesForm';
import AbroadCourseForm from './Course/Abroad/abroad';
import { Box, Button, Typography } from '@mui/material';
import { Grid2 } from '@mui/material';

const CourseManagement = () => {
  const [activeCourse, setActiveCourse] = useState('nepal'); // State to toggle between Nepal and Abroad

  // const handleNepalSubmit = (data) => {
  //   console.log('Nepal Course Data To submit:', data);
  // }
  // const handleAbroadSubmit = (data) => {
  //   console.log('Abroad Course Data to submit:', data); 
  // };

  const toggleCourse = () => {
    setActiveCourse((prev) => (prev === 'nepal' ? 'abroad' : 'nepal'));
  };

  return (
    <Box sx={{ 
      // padding: { xs: 2, sm: 3 } ,
      // backgroundColor: "red",
      paddingBottom: 3,
      display: "flex",
      flexDirection:'column'
      }}> {/* Padding is responsive */} 
      
      {/* Switch Button */}
      <Box
        sx={{
          width: "100%",
          textAlign:'center'
        }}
      >
        <Button
          variant="contained"
          onClick={toggleCourse}
          sx={{
            width:"50%",
            mb: 2, // margin bottom to add space between the button and the forms
            fontSize: { xs: '0.875rem', sm: '1rem' }, // Responsive font size for button
            padding: { xs: '8px 16px', sm: '10px 20px' }, // Padding adjustment for different screen sizes
          }}
        >
          {activeCourse === 'nepal' ? 'Switch to Abroad Course' : 'Switch to Nepal Course'}
        </Button>
      </Box>

      {/* Course Form Title */}
      <Typography
        variant="h5"
        sx={{
          textAlign: 'center',
          fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' }, // Responsive title font size
          fontWeight: 'bold',
          marginBottom: 3
        }}
      >
        {activeCourse === 'nepal' ? 'Nepal Course Management' : 'Abroad Course Management'}
      </Typography>

      {/* Conditionally render Nepal or Abroad Course Form based on activeCourse */}
      <Grid2 container spacing={3} justifyContent="center">
        {activeCourse === 'nepal' && (
          <Grid2 xs={12} sm={10} md={8}>
            <NepalCourseForm/>
          </Grid2>
        )}

        {activeCourse === 'abroad' && (
          <Grid2 xs={12} sm={10} md={8}>
            <AbroadCourseForm />
          </Grid2>
        )}
      </Grid2>
    </Box>
  );
};

export default CourseManagement;
