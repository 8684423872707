import React, { useState, useEffect } from 'react';
import ApiClient from '../../../config/configAxios';
import { toast } from "react-toastify";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';

const NoticeForm = ({ open, onClose, editData }) => {
  const [title, setTitle] = useState('');
  const [onDate, setOnDate] = useState('');
  const [detail, setDetail] = useState('');
  const [photo, setPhoto] = useState(null);

  // console.log("Edit data from table:",editData);
  useEffect(() => {
    if (editData) {
      setTitle(editData.notice_title);
      setOnDate(editData.notice_on);
      setDetail(editData.notice_detail);
      setPhoto(editData.notice_photo || null);
    }
  }, [editData]);

   const handleFileChange = (e) => {
    setPhoto(e.target.files[0]);
    
  };

  const handleSubmit = () => {

    const formData = new FormData();
    formData.append("notice_title",title);
    formData.append("notice_on",onDate);
    formData.append("notice_detail",detail);
    formData.append("notice_photo",photo);

    // console.log('Submitted Notice Data:', formData);

    if(editData===null){
      ApiClient.post(`/notices`,formData,{
        headers:{
          "Content-Type":'multipart/form-data'
        }
      }).then((response)=>{
        // console.log(response);
        if(response.data.success){
          toast.success("New Notice Created.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }else{
          toast.error("Sorry, unable to Insert.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }).catch((error)=>{
        console.log(error)
      });
    }else{
      ApiClient.patch(`/notices/${editData.id}`,formData,{
        headers:{
          "Content-Type":'multipart/form-data'
        }
      }).then((response)=>{
        console.log(response);
        if(response.data.updateResult){
          toast.success("Record Updated.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
          
        }else{
          toast.error("Sorry, Record Not Updated.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }).catch((error)=>{
        console.log(error)
      });

    }

    // Clear form fields after submission
    setTitle('');
    setOnDate('');
    setDetail('');
    setPhoto(null);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{editData ? 'Edit Notice' : 'Add Notice'}</DialogTitle>
      <DialogContent>
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Notice On"
          type="date"
          variant="outlined"
          fullWidth
          value={onDate}
          onChange={(e) => setOnDate(e.target.value)}
          sx={{ marginBottom: 2 }}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          label="Details"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={detail}
          onChange={(e) => setDetail(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
       <TextField
          label="Choose a File"
          type="file"
          variant="outlined"
          fullWidth
          InputLabelProps={{ shrink: true }}
          onChange={handleFileChange}
          sx={{ marginBottom: 2 }}
        />
        {/* {photo && <p style={{ marginTop: '10px' }}>File Selected</p>} */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NoticeForm;
