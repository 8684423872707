import React from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const  preparationTips = ({ preparationTips, setPreparationTips, required }) => {
  const handlePreparationTipsChange = (index, value) => {
    const newPreparationTips = [...preparationTips];
    newPreparationTips[index] = value;
    setPreparationTips(newPreparationTips);
  };

  const handleDeletePreparationTip = (index) => {
    const newPreparationTips = preparationTips.filter((_, i) => i !== index);
    setPreparationTips(newPreparationTips);
  };

  return (
    <>
      {preparationTips.map((tip, index) => (
        <TextField
          key={index}
          label={`Preparation Tip ${index + 1}`}
          value={tip}
          onChange={(e) => handlePreparationTipsChange(index, e.target.value)}
          fullWidth
          margin="normal"
          required={required}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setPreparationTips([...preparationTips, ''])}>
                  <AddIcon />
                </IconButton>
                {preparationTips.length > 1 && (
                  <IconButton onClick={() => handleDeletePreparationTip(index)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      ))}
    </>
  );
};

export default  preparationTips;
