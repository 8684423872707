import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import TeamMemberForm from './memberform';
import TeamMemberTable from '../../../RecordTable/StickyHeaderTable';
import ApiClient from '../../../../config/configAxios';

const columns = ["S.N","Member Name","Designation","Member Words","Member Pic","Image Format"];

const Member = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    member_name: "",
    desig: "",
    member_words: "",
    member_photo: "",
  });
  const [editIndex, setEditIndex] = useState(null);

  // Handle edit button click
  const handleEdit = (data) => {
    // console.log("Editing Data:",data);
    if(data){
      // console.log("edit request");
      setEditIndex(true);
    }else{
      setEditIndex(null);
    }
    setFormData(data);
    
    setOpen(true);
  };


  // Open the form dialog for adding a new member
  const handleOpenForm = () => {
    setEditIndex(null);
    setFormData({ member_name: "", desig: "", member_words: "", intro: "", member_photo: "" });
    setOpen(true);
  };

  useEffect(()=>{
    const fetchTeamMember = ()=>{
      ApiClient.get(`/teamMember`).then((response)=>{
        // console.log(response);
        setTeamMembers(response.data.TeamMember);
      }).catch((error)=>{
        console.log(error);
      });
    }
    fetchTeamMember();
  },[]);

  return (
    <Box 
      sx={{ 
        // padding: 4,
        // backgroundColor:"blue",
        width:{xs:"100%",sm:"90%",md:"96%"}
      }}>
      {/* Add New Team Member Button */}
      <Button variant="contained" color="primary" onClick={handleOpenForm} 
        sx={{mb:2}}
      >
        Add New Team Member
      </Button>

      {/* Team Member Form Dialog */}
      <TeamMemberForm
        open={open}
        setOpen={setOpen}
        formData={formData}
        setFormData={setFormData}
        // handleSubmit={handleSubmit}
        editIndex={editIndex}
      />

      {/* Team Member Table */}
      <Box
        sx={{
          backgroundColor:"yellow",
        }}
      >
          <TeamMemberTable
            rows={teamMembers}
            column={columns}
            url={'/teamMember'}
            onEdit={handleEdit}
          />
      </Box>
    </Box>
  );
};

export default Member;
