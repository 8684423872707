import React, { useEffect,useState } from "react";
import {Box, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import DashboardCard from "./dashboardCard";
import QueryTable from './student_query_table/QueryTable';
import apiClient from "../config/configAxios";
import FilterSection from "./filter_record/filter_section";

const DashboardContentComponent = ()=>{
  const [totalStatus,setTotalStatus] = useState({});
  useEffect(()=>{
    const fetchTotalStatus = ()=>{
      apiClient.get(`/additional/totalStudents`).then((response)=>{
        console.log(response.data);
        setTotalStatus(response.data);
      }).catch((error)=>{
        console.log(error);
      })
    }
    fetchTotalStatus();
  },[]);
    return(
        <Box component="main" sx={{ flexGrow: 1, p: 3,  }}>
          <Grid container spacing={3} sx={{justifyContent:"center",alignItems:"center"}}>
             
                <DashboardCard title="Total Students" value={totalStatus.totalStudentsValue} />
                <DashboardCard title="Total Courses" value={totalStatus.totalCourses} />
              
          </Grid>
          <Box>
              
              <FilterSection />
          </Box>
          <Box
            sx={{
              // backgroundColor:"red",
              width:{xs:"100%",sm:"80%"},
              mt:4
            }}
          >
            <Typography variant="h4" fontWeight='600' sx={{fontSize:{xs:"1.2rem",sm:"1.5rem",md:"2rem"}}} gutterBottom>Student Query</Typography>
            <QueryTable />
          </Box>
      </Box>
    )
}
export default DashboardContentComponent;