import React, { useEffect, useState } from 'react';
import {
  Box,
  Button
} from '@mui/material';
import ServiceForm from './servicesForm';
import ServiceTableList from '../../../RecordTable/StickyHeaderTable';
import ApiClient from '../../../../config/configAxios';
const columns = ["S.N","Service Title","Service Detail","Course Type"];

const ServiceTable = () => {
  const [services, setServices] = useState([]); // State to manage the services list
  const [isDialogOpen, setIsDialogOpen] = useState(false); // State to control form dialog
  const [editData, setEditData] = useState(null); // State to hold data for editing

  // Open dialog for adding a new service
  const handleAddService = () => {
    setEditData(null);
    setIsDialogOpen(true);
  };

  // Open dialog for editing a service
  const handleEditService = (service) => {
    setEditData(service);
    setIsDialogOpen(true);
  };

  useEffect(()=>{
    const fetchService = ()=>{
      ApiClient.get('/services').then((response)=>{
        // console.log(response);
        setServices(response.data.servicesDetail);
      }).catch((error)=>{
        console.error('Axios Error:', error.message);
        console.error('Error Config:', error.config);
        console.error('Error Request:', error.request);
      });
    }
    fetchService();
  },[])
  return (
    <Box 
      sx={{ 
        // padding: 4,
        width:{xs:"100%",sm:"90%",md:"96%"},
        // backgroundColor:"yellow",
      }}>
      <Button variant="contained" onClick={handleAddService} sx={{ marginBottom: 2 }}>
        Add New Service
      </Button>
      <ServiceTableList rows={services} column={columns} url={'/services'} onEdit={handleEditService}/>
      <ServiceForm
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        editData={editData}
      />
    </Box>
  );
};

export default ServiceTable;
