import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import apiClient from '../../config/configAxios';
import { toast } from "react-toastify";

const FilterForm = ({setFilteredRecord}) => {
    // {name,graduated_college,address,qualification,admission_on,preparationTest,admitted_college,program}
  const [filters, setFilters] = useState({
    name: '',
    graduated_college: '',
    address: '',
    qualification: '',
    admission_on: '',
    preparationTest: '',
    admitted_college: '',
    program: ''
  });

  const textfieldStyle ={ '& .MuiInputBase-root': { 
    padding: '0px',
    // border:"1px solid red",
    fontSize:"12px",
    height:"40px",
    textAlign:"center"
    },
    '& .MuiInputLabel-root': {
        fontFamily:"Roboto-Serif",
        // color: 'green', // Custom label color
        fontWeight: 'bold', // Custom label font weight
        fontSize: '12px', // Custom label font size
        // transform: 'translateY(50%)', // Vertically center the label initially
        transition: 'transform 0.4s ease', // Smooth transition for label movement
      }
    }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(filters); // Process the filters (e.g., send to backend)
    apiClient.get(`/additional/filteredRecords`,{
        params: filters, // Pass the filters as query parameters
      }).then((response)=>
    {
        console.log(response);
        setFilteredRecord(response.data);
        if(response.data.length <1){
          toast.warning("No Record Found.",{
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
    }).catch((error)=>{
        console.log(error);
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit} 
        sx={{ width:{xs:"100%",sm:"96%",md:"90%"},
            display: 'flex', 
            flexDirection: 'column', 
            border:'1px solid rgba(0,0,0,0.1)',
            p:2,
            gap: 2 }}>
      <TextField
        label="Name"
        name="name"
        value={filters.name}
        onChange={handleChange}
        variant="outlined"
        sx={textfieldStyle}
      />
      <TextField
        label="Graduated College"
        name="graduated_college"
        value={filters.graduated_college}
        onChange={handleChange}
        variant="outlined"
        sx={textfieldStyle}
      />
        <TextField
          label="Address"
          name="address"
          value={filters.address}
          onChange={handleChange}
          variant="outlined"
          sx={textfieldStyle}
        />
      <TextField
        label="Qualification"
        name="qualification"
        value={filters.qualification}
        onChange={handleChange}
        type="text"
        variant="outlined"
        sx={textfieldStyle}
      />
      <TextField
        label="Admission On"
        name="admission_on"
        value={filters.admission_on}
        onChange={handleChange}
        type="date"
        variant="outlined"
        sx={textfieldStyle}
        InputLabelProps={{ shrink: true }}
      />
        <TextField
          label="Preparation Test"
          name="preparationTest"
          value={filters.preparationTest}
          onChange={handleChange}
          variant="outlined"
          sx={textfieldStyle}
        />
      <TextField
        label="Admitted College"
        name="admitted_college"
        value={filters.admitted_college}
        onChange={handleChange}
        type="text"
        variant="outlined"
        sx={textfieldStyle}
      />
      <TextField
        label="Program"
        name="program"
        value={filters.program}
        onChange={handleChange}
        variant="outlined"
        sx={textfieldStyle}
      />
      <Button type="submit" variant="contained" color="primary">Filter</Button>
    </Box>
  );
};

export default FilterForm;
