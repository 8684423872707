import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Buffer } from 'buffer';
import { Button } from '@mui/material';

export default function FilteredRecord({ rows: listRecord, column }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState(listRecord);

  const table_column = ["sid","s_name",
  "email","s_contact","s_address","graduated_college","admission_on","qualification",
  "destination","preparationTest","admitted_college","program","feedback","alumini"
]

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    setRows(listRecord);
  }, [listRecord]);

  const handleDownload = () => {
    if (!rows.length) return;
  
    // Escape commas and double quotes in the data
    const escapeCsvValue = (value) => {
      if (typeof value === 'string') {
        // Replace any double quotes with two double quotes and wrap the value in double quotes
        return `"${value.replace(/"/g, '""')}"`;
      }
      return value; // Return non-string values as-is
    };
  
    // Generate CSV header
    const csvHeader = column.map(escapeCsvValue).join(',');
  
    // Generate CSV rows
    const csvRows = rows.map((row) => {
      return table_column
        .map((col) => {
          const value = row[col];
          if (value instanceof Uint8Array || value?.type === 'Buffer') {
            return ''; // Skip binary data in the CSV
          }
          return escapeCsvValue(typeof value === 'object' ? JSON.stringify(value) : value);
        })
        .join(',');
    });
  
    // Combine header and rows
    const csvContent = [csvHeader, ...csvRows].join('\n');
  
    // Create a Blob and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Students_Record.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', mb: 3 }}>
      <TableContainer sx={{ maxHeight: 440, maxWidth: { xs: '100%', sm: '80%', md: '96%' } }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {column.map((column, index) => (
                <TableCell
                  key={index}
                  sx={{
                    minWidth: '100px',
                    fontWeight: '600',
                    fontFamily: 'calibri',
                  }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ textAlign: 'center' }}>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.dcId}>
                    {Object.keys(row).map((col, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          fontSize: '12px',
                          paddingLeft: '10px',
                        }}
                      >
                        {row[col] instanceof Uint8Array || row[col]?.type === 'Buffer' ? (
                          <img
                            src={`data:image/${row.imageFormat || 'jpeg'};base64,${Buffer.from(row[col]).toString('base64')}`}
                            alt="Table pic"
                            style={{ width: '60px', height: '60px' }}
                          />
                        ) : typeof row[col] === 'object' ? (
                          JSON.stringify(row[col])
                        ) : (
                          row[col]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          maxWidth: { xs: '100%', sm: '80%', md: '96%' },
        }}
      />
      <Button
        variant="contained"
        sx={{ m: 1 }}
        onClick={handleDownload}
      >
        Download Record
      </Button>
    </Paper>
  );
}
