import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import apiClient from '../../../config/configAxios';
import EventForm from './eventForm'; 
import StickyTable from '../../RecordTable/StickyHeaderTable'; 
// import React,{useEffect} from "react";
// import { Box } from "@mui/material";
// import apiClient from "../../../config/configAxios";
const columns = ["S.N", "Title", "Date",  "Description","Banner","Image Format"];

const EventInformation = () => {
  const [openForm, setOpenForm] = useState(false);
  const [editData, setEditData] = useState(null);
  const [events, setEvents] = useState([]);
  
  // Fetch events data on component mount
  useEffect(() => {
    const fetchEvents = ()=>{
            apiClient.get(`/events`).then((response)=>{
              console.log(response);
              setEvents(response.data.eventsDetail);
            }).catch((error)=>{
              console.log(error);
            });
          }
          fetchEvents();
  }, []);

  // Open form for editing or adding new event
  const handleEdit = (item) => {
    setEditData(item);
    setOpenForm(true);
  };

  // Close form and clear edit data
  const handleClose = () => {
    setEditData(null);
    setOpenForm(false);
  };

  

  return (
    <Box sx={{ padding: 2 }}>
      <Button variant="contained" onClick={() => setOpenForm(true)}>
        Add New Event
      </Button>

      {/* Event Table (using StickyTable) */}
      <Box sx={{ marginTop: 3 }}>
        <StickyTable 
          rows={events} 
          column={columns} 
          url={'/events'}
          onEdit={handleEdit} 
        />
      </Box>

      {/* Event Form (for adding/editing events) */}
      <EventForm open={openForm} onClose={handleClose} editData={editData} />
    </Box>
  );
};

export default EventInformation;
// const EventInformation = ()=>{
//   useEffect(()=>{
//     const fetchEvents = ()=>{
//       apiClient.get(`/events`).then((response)=>{
//         console.log(response);
//       }).catch((error)=>{
//         console.log(error);
//       });
//     }
//     fetchEvents();
//   },[]);
//   return(
//     <Box>
//       Hello
//     </Box>
//   );
// }
// export default EventInformation;