import React, { useState, useEffect } from 'react';
import apiClient from '../../../config/configAxios';
import { toast } from 'react-toastify';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2
} from '@mui/material';

const EventForm = ({ open, onClose, editData }) => {
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [photo, setPhoto] = useState(null);
  console.log(apiClient);
  console.log("what is the edit value for event:", editData);

  // Load data if editData is provided
  useEffect(() => {
    if (editData) {
      console.log("changing .............")
      setTitle(editData.events_title);
      setDate(editData.events_date);
      setDescription(editData.events_detail);
      setPhoto(editData.events_photo || null);
    }
  }, [editData]);

  const handleFileChange = (e) => {
    setPhoto(e.target.files[0]);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("events_title", title);
    formData.append("events_date", date);
    formData.append("events_detail", description);
    formData.append("events_photo", photo);

    console.log(editData);
    console.log("this is form data:",formData);
    if (editData === null) {
      apiClient.post(`/events`, formData, {
        headers: {
          "Content-Type": 'multipart/form-data'
        }
      }).then((response) => {
        if (response.data.success) {
          toast.success("New Event Added.", {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
            toast.error("Sorry, Record Not Inserted.", {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }).catch((error) => {
        console.log(error)
      });
    } else {
      apiClient.patch(`/events/${editData.id}`, formData, {
        headers: {
          "Content-Type": 'multipart/form-data'
        }
      }).then((response) => {
        if (response.data.success) {
          toast.success("Record Updated.", {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          toast.error("Sorry, Record Not Updated.", {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }).catch((error) => {
        console.log(error)
      });
    }

    // Reset form fields
    setTitle('');
    setDate('');
    // setLocation('');
    setDescription('');
    setPhoto(null);
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      sx={{ 
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: "12px",
        padding: "2px"
      }}
    >
      <DialogTitle>{editData ? 'Edit Event' : 'Add Event'}</DialogTitle>
      <DialogContent sx={{ minWidth: '300px', fontSize: "12px" }}>

        {/* Event Title */}
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ fontSize: "12px", marginBottom: 2 }}
        />

        {/* Event Photo */}
        <TextField
          type="file"
          fullWidth
          onChange={handleFileChange}
          InputLabelProps={{ shrink: true }}
          sx={{ fontSize: '12px', marginBottom: 2 }}
        />

        {/* Using Grid2 for layout */}
        <Grid2 container spacing={2}>
          <Grid2 item xs={12} md={6}>
            <TextField
              label="Event Date"
              type="date"
              variant="outlined"
              fullWidth
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
              InputLabelProps={{
                shrink: true
              }}
              sx={{ fontSize: "12px", marginBottom: '16px' }}
            />
          </Grid2>
        </Grid2>

        {/* Event Description */}
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{ fontSize: "12px", marginBottom: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventForm;
